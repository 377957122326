import "../App.css";
import kvark_logo from "../assets/kvark_logo.png";
import martonszabolcs from "../assets/martonszabolcs.png";
import atrium1 from "../assets/photos/atrium1.png";
import atrium2 from "../assets/photos/atrium2.png";
import atrium3 from "../assets/photos/atrium3.png";
import fb from "../assets/icons/fb.png";
import insta from "../assets/icons/insta.png";
import yt from "../assets/icons/yt.png";
import spoti from "../assets/icons/spoti.png";
import event from "../assets/logos/event.png";
import szesz from "../assets/logos/szesz.png";
import torulaBlack from "../assets/logos/torulaBlack.png";
import kvarkMobile from "../assets/kvark_mobile.png";

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import GridLines from "react-gridlines";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ModalImage from "react-modal-image";

const firebaseConfig = {
  apiKey: "AIzaSyCEB7TGU7Pds5L5ga3hDy7OrZl-KLARoSk",
  authDomain: "kvark-511ba.firebaseapp.com",
  projectId: "kvark-511ba",
  storageBucket: "kvark-511ba.appspot.com",
  messagingSenderId: "596623404975",
  appId: "1:596623404975:web:a8b7453c42536168d7095a",
  measurementId: "G-BSP8F9Q3H3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    var userLang = navigator.language || navigator.userLanguage;
    setLanguage(userLang.includes("hu") ? "hu" : userLang);
  }, []);

  const isHu = language === "hu";

  return (
    <div className="App font-spaceGrotesk">
      <div id="fb-root"></div>
      <script
        async
        defer
        crossorigin="anonymous"
        src="https://connect.facebook.net/hu_HU/sdk.js#xfbml=1&version=v13.0&appId=778005422729456&autoLogAppEvents=1"
        nonce="ofWZBuYH"
      ></script>
      <div className="fixed bottom-0 z-20 pb-2 pt-2  w-screen bg-black flex justify-center items-center">
        <img
          alt={"Gyori szesz"}
          src={szesz}
          onClick={() => openInNewTab("http://www.gyoriszesz.hu/hu")}
          className="w-8 h-8 cursor-pointer"
        />
        <img
          alt={"Torula"}
          src={torulaBlack}
          onClick={() => openInNewTab("https://www.torula.hu/")}
          className="w-10 h-10 mr-4 ml-4 cursor-pointer"
        />
        <img
          alt={"Event studio"}
          src={event}
          onClick={() => openInNewTab("https://www.eventstudio.hu/")}
          className="w-10 h-10 cursor-pointer"
        />
      </div>
      <div
        onClick={() =>
          openInNewTab(
            "https://eventfrog.de/en/p/concert/other-music-genres/the-symphony-of-transience-kvark-music-7249169156515290704.html"
          )
        }
        className="p-2 z-30  cursor-pointer fixed rounded-3xl right-5 bottom-16 md:right-20 bg-white flex items-center justify-center"
      >
        <div className="p-2">
          <h1 className="text-black font-bold text-xl">
            House of Music | Berlin
          </h1>
          <p className="text-black text-xl"> 2024. dec 7. 20:00</p>
        </div>
        <div className="font-bold mx-5 text-xl ">Info</div>
      </div>

      <Helmet>
        <meta name="title" content={"KVARK"} />
        <meta
          name="description"
          content={
            "The orchestra of KVARK consists of twelve musicians: strings, brass instruments, piano, drums, and a bass guitar. The project is an outstanding artistic and social event in which the audience participates in a musical experiment that’s guaranteed to get one out of their comfort zone. "
          }
        />
        <meta
          name="og:description"
          content={
            "The orchestra of KVARK consists of twelve musicians: strings, brass instruments, piano, drums, and a bass guitar. The project is an outstanding artistic and social event in which the audience participates in a musical experiment that’s guaranteed to get one out of their comfort zone. "
          }
        />
        <meta property="og:site_name" content="MARTON SZABOLCS || KVARK" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={"MARTON SZABOLCS || KVARK"} />
        <meta
          property="og:description"
          content={
            "The orchestra of KVARK consists of twelve musicians: strings, brass instruments, piano, drums, and a bass guitar. The project is an outstanding artistic and social event in which the audience participates in a musical experiment that’s guaranteed to get one out of their comfort zone."
          }
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:secure_url" content={`/kvark_home.png`} />
        <meta property="og:image" content={`/kvark_home.png`} />
      </Helmet>

      <div className="bg-black text-left h-screen w-screen md:bg-kvark bg-cover bg-fixed">
        <img
          alt={"kvark"}
          src={kvarkMobile}
          className="absolute top-0 right-0 bottom-0 h-full md:hidden"
        />
        {/*  <div className="absolute z-20 flex justify-center align-center right-10 md:right-20 top-16">
          <div
            onClick={() => setLanguage("en")}
            style={{ color: language !== "hu" ? "#A5263B" : "white" }}
            className="text-white cursor-pointer font-bold"
          >
            EN
          </div>

          <div className="text-white font-archivo mr-2 ml-2 font-bold">|</div>
          <div
            onClick={() => setLanguage("hu")}
            style={{ color: isHu ? "#A5263B" : "white" }}
            className="text-white cursor-pointer font-bold"
          >
            HU
          </div>
        </div> */}

        <div className="text-left pt-4 pb-4 w-screen bg-black absolute flex flex-col bg-opacity-70 justify-center items-center md:flex bottom-8 pb-8">
          <div>
            <h4 className="text-3xl font-bold mb-2 text-center text-white">
              {isHu ? "ESEMÉNYEK" : "EVENTS"}
            </h4>
            {!isHu && (
              <>
                <h3 className="text-s text-white font-bold">
                  {isHu
                    ? "2024. december 7. - House of Music  | Berlin"
                    : "2024. dec 7. - House of Music  | Berlin"}
                </h3>

                <div className="h-1 my-2 bg-white" />
              </>
            )}
            <h3 className="text-xs text-white">
              {isHu
                ? "2024. július 7. - Zsolnay Fényfesztivál  | Pécs"
                : "2024. july 7. - Zsolnay Lightfestival  | Pécs"}
            </h3>
            <h3 className="text-xs text-white">
              {isHu
                ? "2024. június 8. - Art Piknik  | Pannonhalma"
                : "2024. june 8. - Art Piknik  | Pannonhalma"}
            </h3>
            
            <h3 className="text-xs text-white">
              {isHu
                ? "2024. május 12. - Budapest 100 | Budapest"
                : "2024. may 12. -  Budapest 100 | Budapest"}
            </h3>
            <h3 className="text-xs text-white">
              {isHu
                ? "2024. május 9. - Ada Vilhan Opening | Budapest"
                : "2024. may 9. -  Ada Vilhan Opening | Budapest"}
            </h3>
            <h3 className="text-xs text-white">
              {isHu
                ? "2023. október 8. - Torula | Győr"
                : "2023. october 8. - Torula | Győr"}
            </h3>
            <h3 className="text-xs text-white">
              {isHu
                ? "2023. szeptember 29. - Serum Light Festival | Somorja SK"
                : "2023. september 29. - Serum Light Festival | Somorja SK"}
            </h3>
            <h3 className="text-xs text-white">
              {isHu
                ? "2023. június 17-18. - Veszprém-Balaton 2023 | Veszprém"
                : "2023. june 17-18. - Veszprém-Balaton 2023 | Veszprém"}
            </h3>
            <h3 className="text-xs text-white">
              {isHu
                ? "2023. április 20. - Budapesti Tavaszi Fesztivál | Kristály Színtér"
                : "2023. april 20. - Budapesti Tavaszi Fesztivál | Kristály Színtér"}
            </h3>

            <h3 className="text-xs text-white">
              {isHu
                ? "2022. október - Átrium | Budapest"
                : "2022. october - Átrium | Budapest"}
            </h3>
            <h3 className="text-xs text-white">
              {isHu
                ? "2022. május - Torula művésztér | Győr"
                : "2022. may - Torula ART | Győr"}
            </h3>
          </div>
        </div>
        <div className="mx-8 pt-12 absolute bottom-5 md:relative">
          <img
            src={kvark_logo}
            alt="Torula"
            className="mt-0 m-2 mt-4 w-64 hidden md:block"
          />
        </div>
      </div>

      {/*   <div
        onClick={() => openInNewTab("https://tixa.hu/torula_gyarkoncert")}
        className="p-2 z-30  cursor-pointer fixed rounded-3xl right-5 bottom-10 md:right-20 bg-green flex items-center justify-center"
      >
        <div className="p-2">
          <h1 className="text-black font-bold text-xl">
            {isHu ? "KVARK Akusztik" : "KVARK Acoustic"}
          </h1>
          <p className="text-black text-left text-xl">
            {" "}
            {isHu ? "Torula | Győr" : "Torula | Győr"}
          </p>
        </div>
        <div className="font-bold mx-5 text-xl border-l pl-2 h-max ">
          {isHu ? "Jegyek" : "Tickets"}
        </div>
      </div> */}

      <GridLines
        className="text-left pt-24 pb-24 w-screen bg-bgwhite flex flex-col items-center"
        cellWidth={125}
        strokeWidth={0.5}
        cellWidth2={125}
      >
        <div className="m-10 md:w-3/5 md:m-0 mt-0">
          <div class="aspect-w-16 mt-10 aspect-h-9 video rounded">
            <iframe
              title="KVARK European Cultural Capital 2023"
              src="https://www.youtube.com/embed/53tiW-32sW8"
              frameborder="5"
              className="rounded"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="text-xl mt-10 text-black">
            {isHu
              ? `Emberek vagyunk. Ennek a jelentése és jelentősége kultúránként,
            társadalmanként, egyénenként eltérő lehet. Mégis összeköt minket
            belső, legféltettebb énünk, elemi érzelmeink működési mechanizmusa
            és a közös félelmeink. A koncerten bemutatott egyedi audiovizuális
            installáció először látni engedi a saját lényünk mélyébe vezető
            útvesztő falait, hagy köztük eltévedni, és felteszi a kérdést:
            mekkora értéket ad nekünk a saját mulandóságunk? Erre próbál meg
            húsbavágó válaszokat adni a három részre tagolt zenei utazás – és
            egy élményt, ami által gazdagabbá válunk.`
              : "We’re human. The meaning and burden of this fact vary based on our individual cultural background, social status, our nature. Still, the innermost fragile pieces of our existence, the very working mechanisms of our feelings, and our shared elementary fears all tie us together. The unique audiovisual installation that’s made for the production at first sheds light on the walls of the maze that leads us to the deepest of ourselves, lets us wander and get lost in it while stating the question: how much worth does our transience hold? This music production aims to give meat-cuttingly raw answers to these questions in a three-part journey – and an experience that makes us more as a person. "}
          </div>
        </div>
      </GridLines>

      <div className=" text-left pt-24 pb-24 w-screen bg-black flex flex-col items-center">
        <div className="m-10 md:w-3/5 md:m-0 flex flex-col mt-0">
          <div className="flex justify-center items-center">
            <div className="flex mr-10 flex-col">
              <ModalImage
                small={atrium1}
                large={atrium1}
                alt="KVARK"
                className="rounded"
              />
              <div className="flex mt-2 ml-10 justify-center items-center">
                <ModalImage
                  small={atrium2}
                  large={atrium2}
                  alt="KVARK"
                  className="rounded"
                />
              </div>
            </div>
            <div className="h-full">
              <ModalImage
                small={atrium3}
                large={atrium3}
                alt="KVARK"
                className="rounded"
              />
            </div>
          </div>
          <h4 className=" text-3xl mt-10 mb-10 font-bold text-white">
            {isHu
              ? "AMIT A KVARKRÓL TUDNI ÉRDEMES"
              : "WHAT’S THERE TO KNOW ABOUT KVARK? "}
          </h4>
          <div className="text-xl mt-10 lg:mt-0 text-white">
            {isHu
              ? `A KVARK zenekara 12 főből áll: vonósok, fúvósok, valamint zongora,
            dob és basszusgitár. A projekt egy egyedülálló művészeti és
            közösségi esemény. A három részre tagolt műsor alatt egy zenei
            kísérlet részesei lehetünk, aminek keretében garantáltan kilépünk a
            komfortzónánkból és együtt tapasztalhatunk valami egészen
            különleges, olykor már-már furcsán szürreális élményt.`
              : `The orchestra of KVARK consists of twelve musicians: strings, brass instruments, piano, drums, and a bass guitar. The project is an outstanding artistic and social event in which the audience participates in a musical experiment that’s guaranteed to get one out of their comfort zone. The three-chaptered experience is special, collectively shared, and sometimes even a bit surreal, while every minute of it has its own individual kind of gravity.`}
          </div>
        </div>
      </div>

      <GridLines
        className="text-left pt-24 pb-24 w-screen  flex flex-col items-center"
        cellWidth={125}
        strokeWidth={0.5}
        cellWidth2={125}
      >
        <div className="m-10 md:w-3/5 md:m-0 mt-0">
          <div class="aspect-w-16 mt-10 aspect-h-9 video rounded">
            <iframe
              title="KVARK Interview"
              src="https://www.youtube.com/embed/o9iQsIwO1zo"
              frameborder="5"
              className="rounded"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="text-xl text-center italic mt-10 text-black">
            {isHu
              ? `“Pokolra tettem a lelkem, mikor a mulandóhoz úgy ragaszkodtam,
            mintha nem volna mulandó.”`
              : `“I condemned my soul to hell when I clung to the ephemeral as if it were not ephemeral.“`}
          </div>
          <div className="text-l text-right mt-2 text-black">
            {isHu
              ? "Szent Ágoston vallomásai"
              : "The Confessions of St. Augustine"}
          </div>

          <div className="text-xl mt-10 text-black">
            {isHu
              ? `Szokás mondani: az életben egy dolog állandó, az pedig a változás. A
            KVARK az emberi élet legelemibb részét mutatja be, a változás
            pillanatát. Azt a pillanatot, amiből naponta akár több ezer is
            megtörténik velünk, olykor szinte észrevétlenül. Tudjuk, hogy a
            létünk nem tarthat örökké. És mint mindennek, ami véges, tétje is
            van. A zenei kísérlet szakaszai (elidegenedés, utazás/flow és
            megérkezés) azonos alaptételen nyugszanak: tartanak valahonnan és
            valami felé, végesek, hiszen előre meghatározott keretek között
            működnek. Akárcsak az élet. Egyedül az alkotás az, amiben örökké
            élhetünk.`
              : `As the saying goes, the only thing that’s static in life is change itself. KVARK magnifies the smallest particle of human existence: the moment of alteration. 
              The moment, from which even thousands can happen to us every day, most of the time unnoticed. We know that our existence cannot last forever. And as everything that is finite, has a higher stake. The stages of the musical experiment (alienation, travel/flow, and arrival) are based on the same basic premise: they lead from somewhere towards something, they are finite, since they operate within a predetermined framework. Just like life. Creation is the only thing in which we can live forever.              
            `}
          </div>
          <div className="text-xl mt-10 text-black">
            {isHu
              ? `Látod, mekkora áldás, hogy halandók vagyunk?`
              : `Do you see what a blessing it is that we are mortal?`}
          </div>
        </div>
      </GridLines>
      <div className=" text-left pt-24 pb-24 w-screen bg-black flex flex-col items-center">
        <div className="m-10 md:w-3/5 md:m-0 mt-0">
          <h4 className=" text-3xl  mb-12 font-bold text-green">
            {isHu ? "A STÁB" : "THE CREW"}
          </h4>
          <div className="lg:flex">
            <img
              src={martonszabolcs}
              alt="MartonSzabolcs"
              className="w-40 h-40 mr-5 rounded"
            />
            <div className="flex flex-col">
              <div className="text-xl mt-10 mb-5 lg:mt-0 text-white">
                <b>MARTON SZABOLCS</b>
              </div>
              <div className="text-xl mt-10 mb-4 lg:mt-0 text-white">
                {isHu
                  ? `Marton Szabolcs producer, zeneszerző. Az első debütálása
                dalszerzőként a ’Préda’ előadásához fűződik, amihez 2014-ben
                készített dalokat az Aurora Art felkérésére. Ugyanebben az évben
                indult az első saját zenekara is, a We are Infinite, amivel a
                budapesti Talentométer döntőjébe kerültek, illetve bejutottak a
                Hangfoglaló (akkori nevén Cseh Tamás) Program résztvevői közé
                is. 2016-ban ő szerezte a ’Vád’ című darab zenéjét, majd
                2017-ben elkészítette első saját nagylemezét, amit második
                zenekarával, a HÜGE-vel jegyez. Azóta elsődlegesen színházi- és
                filmzenék szerzőjeként van jelen a magyar zeneiparban. `
                  : `Marton Szabolcs, producer, and composer. His first debut, as a songwriter, was with the performance of 'Préda', for which he composed music in 2014 at the request of Aurora Art. In the same year, his first own band, “We are Infinite”, was launched, with which they reached the finals of the Talentometer in Budapest, and made it to the participants of the Hangfoglaló (then known as Tamás Cseh) Program. In 2016, he composed the music for the performance 'Vád', and in 2017 he produced his first LP, which he recorded with his second band, HÜGE. Since then, he has been primarily present in the Hungarian music industry as a composer of theater and film scores.`}
              </div>
              <div className="text-xl mt-10 lg:mt-0 text-white">
                {isHu
                  ? `Szabolcsot már egészen fiatal kamaszkora óta foglalkoztatta az a
                  kérdés, hogyan lehetne erős, jól érthető üzeneteket közvetíteni
                  pusztán instrumentális hangzással. Ez a gondolat végül 2021-re
                  érett benne konkrét zenei programmá, nekifogott a KVARK
                  megtervezésének és megvalósításának. A zeneszerzésen túl még
                  ugyanebben az évben a próbafolyamatok is elindultak, majd a
                  győri Torula Művésztér helyszínén otthonra is talált a kortárs
                  audiovizuális kísérletként körvonalazódó projekt.`
                  : `Since his early teens, Szabolcs has been concerned with the question of how to convey strong, easily understandable messages using only instrumental sounds. This idea finally matured into a concrete music program in 2021, and he started the planning and realization of KVARK. In addition to composing the music, the rehearsals also started in the same year, and the project, which was outlined as a contemporary audiovisual experiment, also found a home at the Torula Művésztér in Győr.`}
              </div>
            </div>
          </div>
        </div>
        <div className="ml-10 mr-10 justify-between flex md:w-3/5 sm:mt-10">
          <div className="flex w-1/3 flex-col mt-10">
            <div className="text-xl mt-10 mb-5 lg:mt-0 text-white">
              <b>RÁKOSI TÍMEA</b>
            </div>
            <div className="text-xl lg:mt-0 text-white">
              {isHu ? "Booking Manager" : "Booking Manager"}
            </div>
          </div>
          <div className="flex w-1/3 flex-col mt-10">
            <div className="text-xl mt-10 mb-5 lg:mt-0 text-white">
              <b>SZABÓ RÉKA</b>
            </div>
            <div className="text-xl lg:mt-0 text-white">Marketing</div>
          </div>
        </div>
        <div className="ml-10 mr-10 justify-between flex md:w-3/5 sm:mt-10">
          <div className="flex w-1/3 flex-col mt-10">
            <div className="text-xl mt-10 mb-5 lg:mt-0 text-white">
              <b>KISS PATRIK</b>
            </div>
            <div className="text-xl lg:mt-0 text-white">Mapping</div>
          </div>
          <div className="flex w-1/3 flex-col mt-10">
            <div className="text-xl mt-10 mb-5 lg:mt-0 text-white">
              <b>KURUCZ ANDRÁS</b>
            </div>
            <div className="text-xl lg:mt-0 text-white">
              {isHu ? "Mapping rendezés" : "Mapping Director"}
            </div>
          </div>
        </div>
        <div className="ml-10 mr-10 justify-between flex md:w-3/5 sm:mt-10">
          <div className="flex w-1/3 flex-col mt-10">
            <div className="text-xl mt-10 mb-5 lg:mt-0 text-white">
              <b>MARTON ZSOLT</b>
            </div>
            <div className="text-xl lg:mt-0 text-white">Design</div>
          </div>
        </div>

        <div className="ml-10 mr-10 justify-between flex md:w-3/5 sm:mt-10">
          <div className="flex w-1/3 flex-col mt-10">
            <div className="text-xl mt-10 mb-5 lg:mt-0 text-white">
              <b>HAJMA PÉTER</b>
            </div>
            <div className="text-xl lg:mt-0 text-white">
              {isHu ? "Hangmérnök" : "Sound Engineer"}
            </div>
          </div>
          <div className="flex w-1/3 flex-col mt-10">
            <div className="text-xl mt-10 mb-5 lg:mt-0 text-white">
              <b>SIPOS BENCE</b>
            </div>
            <div className="text-xl lg:mt-0 text-white">
              {isHu ? "Hangmérnök" : "Sound Engineer"}
            </div>
          </div>
        </div>
      </div>
      <GridLines
        className="text-left pt-24 pb-24 w-screen flex flex-col items-center"
        cellWidth={125}
        strokeWidth={0.5}
        cellWidth2={125}
      >
        <div className="m-10 md:w-3/5 md:m-0 mt-0">
          <div className="w-3/5 ">
            <h4 className=" text-2xl font-bold text-black">
              {isHu ? "Kapcsolat" : "Contact"}
            </h4>
            <div className="text-xl mt-10 text-black">
              hello@martonszabolcs.hu
              <br />
            </div>
            <div className="text-xl mt-2 text-black">
              kvark@torula.hu
              <br />
            </div>
          </div>
          <div className="w-3/5 flex ">
            <img
              alt={"KVARK youtube"}
              onClick={() =>
                openInNewTab(
                  "https://www.youtube.com/channel/UCdFMZv6Wm47-QDMiyGbDF1w"
                )
              }
              src={yt}
              className="w-8 h-8 mr-4 mt-4 cursor-pointer"
            />
            <img
              alt={"KVARK spotify"}
              onClick={() =>
                openInNewTab(
                  "https://open.spotify.com/artist/3mfIMiojq40msnh10snKGP"
                )
              }
              src={spoti}
              className="w-8 h-8 mr-4 mt-4 cursor-pointer"
            />
            <img
              alt={"KVARK instagram"}
              onClick={() =>
                openInNewTab("https://www.instagram.com/martonszabolcs_/")
              }
              src={insta}
              className="w-8 h-8 mr-4 mt-4 cursor-pointer"
            />
            <img
              onClick={() =>
                openInNewTab("https://www.facebook.com/martonszabolcsmusic")
              }
              alt={"KVARK facebook"}
              src={fb}
              className="w-8 h-8 mr-4 mt-4 cursor-pointer"
            />
          </div>
          <div className="mt-24 text-xs text-black">
            {isHu
              ? "© Marton Szabolcs, 2024. Minden jog fenntartva."
              : "© Marton Szabolcs, 2024. All rights reserved."}
          </div>
        </div>
      </GridLines>
    </div>
  );
}

export default App;
