import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./screens/home";
import Introduction from "./screens/introduction";

function Production() {
  useEffect(() => {
    window.location.href = "https://eventfrog.de/en/p/concert/other-music-genres/the-symphony-of-transience-kvark-music-7249169156515290704.html";
  }, []);

  return null;
}

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/introduction" element={<Introduction />} />
        <Route path="/production" element={<Production />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
